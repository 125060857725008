/* Head Design */
.aboutustopbg{
    height: 100vh;
    
    background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.906)), url(../../images/aboutusnew.jpg);
     background-position: top;
    background-size: cover;
    color: white; 
    transition: .5s;
}


.aboutustopbg div{
padding: 10px 200px;
}

.navlinkdesbtn2{
    border-radius: 3% 45% 13% 9%/35% 9% 7% 10%; background-color: #009846;color: white;
}

.aboutustopbg h1{
    color: white;
    font-size: 90px;
    }
    @media screen and (max-width: 990px) {
        .aboutustopbg h1{
            color: white;
            font-size: 70px;
            }
        .aboutustopbg div{
            padding: 10px 40px;
            }
    }


.aboutustopbg div p{
    color: white;
    font-size: 20px;
    }