.contact{
    padding: 30px 0px 60px 0px;
     position: relative; 
     /* background-image: url(../../images/bg.png);  */
     background-position: center;
     background-size: cover;
}
.contact_form_style{
    border: 1px solid black;border-radius: 2% 66% 11% 41% / 34% 21% 52% 19%;
}
.phone_div{
    margin-top: 5px; margin-bottom: 5px;
    border-radius: 2% 66% 11% 41% / 34% 21% 52% 19%;
}
#submit{
    border-radius: 3% 45% 13% 9%/35% 9% 7% 10%; 
    background-color: #F36C38;
    color: white;
    text-align: center;
    text-decoration: none; 
    cursor: pointer;
}
.contacticonlogo{
    width: 20vw;
}
.getintouchh2{
    text-align: left; font-weight: 800; margin-top: 60px;
}



/* Head Design */
.contacttopbg{
    height: 100vh;
    
    /* background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.906)), url(../../images/contactusnew.jpg); */
    background-position: top;
    background-size: cover;
    color: white; 
    transition: .5s;
}


.contacttopbg div{
padding: 10px 200px;
}

.navlinkdesbtn2{
    border-radius: 3% 45% 13% 9%/35% 9% 7% 10%; background-color: #009846;color: white;
}


.contacttopbg div p{
    color: white;
    font-size: 20px;
    }

    .contacttopbg h1{
        color: white;
        font-size: 90px;
        }
        @media screen and (max-width: 990px) {
            .contacttopbg h1{
                color: white;
                font-size: 70px;
                }
            .contacttopbg div{
                padding: 10px 40px;
                }
        }
    