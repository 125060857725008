.franchisetopbg{
    height: 100vh; background-image: url(../../images/franchisetopbg2.png); 
    background-position: bottom; 
    background-size: cover; color: white; 
    transition: .5s;
}
.col-12 h4{
    color: #009846;
    font-weight: 800;
    text-transform: uppercase;
}
.franchise_form_style{
    border: 1px solid black;border-radius: 2% 66% 11% 41% / 34% 21% 52% 19%
}
.abutusdiv{
    padding: 60px 170px;
}
@media screen and (max-width: 990px) {
    .abutusdiv{
        padding: 60px 50px;
    }
}