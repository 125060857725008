.carousel .slider-wrapper{
    width: 100%;
}
.carousel .thumb{
    width: 5%;
    height: 50px;
    /* display: none; */
}
.carousel{
    text-align: center;
}
.topimage2{
    position: absolute;
    width: 20vw;
    top: 0vh;
    right: 0;
}
.topimg3{
    position: absolute;
    width: 20vw;
    top: 47vh;
}