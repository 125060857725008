.desktopnavlink{
    color: #009846 !important;
}

.divsample{
    background-color: red;
}
.navlinkdesbtn{
    border-radius: 3% 45% 13% 9%/35% 9% 7% 10%; background-color: #F36C38;color: white;
}

*{
    font-family: 'Yomogi', cursive;color: #009846;font-weight: 700;
}

.destopnavbarmain{
    background-color: white;
    border-bottom: 1px solid #009846;
}

@media screen and (min-width: 990px) {
    .HideInDesktop {
        display: none;
    }
}

@media screen and (max-width: 990px) {
    .HideInPhone {
        display: none;
    }
}


.sidenav {
    height: 100%;
    width: 0;
    position: fixed;
    z-index: 5;
    top: 0;
    left: 0;
    background-color: white;
    overflow-x: hidden;
    transition: 0.5s;
    padding-top: 60px;
}

    .sidenav a {
        padding: 8px 8px 8px 32px;
        text-decoration: none;
        font-size: 25px;
        color: #818181;
        display: block;
        transition: 0.3s;
    }

        .sidenav a:hover {
            color: black;
        }

    .sidenav .closebtn {
        position: absolute;
        top: 0;
        right: 25px;
        font-size: 36px;
        margin-left: 50px;
    }

#main {
    transition: .5s;
    overflow-x: hidden;
    box-shadow: -2px -2px 2px black;
}
.sidenavdesign{
    background-image: url(../../images/phonenavbg.png);
}

@media screen and (max-height: 990px) {

    .sidenav {
        padding-top: 15px;
    }

        .sidenav a {
            font-size: 26px;
        }
}

.phonebackground{
    background-image: url(.././../images/phonenavbg.png);background-size: cover;
}
